import React from 'react';
import Layout from '../../components/Layout/Layout';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"

const Workshops = () => {
    
    const data = useStaticQuery(graphql`
    query {
        Workshops: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Workshops" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
    return (
        <Layout>
            <HomeLayout>
                {
                    data.Workshops.nodes.map(img=><Img
                        fluid={img.childImageSharp.fluid}
                        alt="IT images"
                        key={img.id}
                      />)
                }
            </HomeLayout>
        </Layout>
    );
};

export default Workshops;